import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    consultantGoals: [],
    consultantGoalsCount: null,
    consultantGoal: {
      id: null,
      id_com_brand: null,
      id_com_consultant: null,
      goal: null,
      digital_goal: null,
      showroom: null,
      phone: null,
      new_customer: null,
      web: null,
      web_distributor: null,
      tahsis_baglanti: null,
      test_drive: null,
      month: null,
      year: null,
      id_com_consultant_goal_status: null,
      id_com_consultant_goal: null,
      id_com_user: null,
      adddate: null,
      addtime: null,
      submitStatus: false,
    },
    consultantGoalSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getConsultantGoalPendings(state) {
      return state.consultantGoals
    },
    getConsultantGoalPendingsCount(state) {
      return state.consultantGoalsCount
    },
    getConsultantGoalPending(state) {
      return state.consultantGoal
    },
    getConsultantGoalPendingSaveStatus(state) {
      return state.consultantGoalSave
    },
  },
  mutations: {
    CONSULTANT_GOAL_PENDINGS_LIST(state, data) {
      state.consultantGoals = data
    },
    CONSULTANT_GOAL_PENDING(state, data) {
      state.consultantGoal = data
    },
    CONSULTANT_GOAL_PENDING_COUNT(state, data) {
      state.consultantGoalsCount = data
    },
    CONSULTANT_GOAL_PENDING_SAVE(state, data) {
      state.consultantGoalSave = data
    },
  },
  actions: {
    consultantGoalPendingsList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/consultant_goal_pendings', data)
        .then(response => {
          commit('CONSULTANT_GOAL_PENDINGS_LIST', response.data.data)
          commit('CONSULTANT_GOAL_PENDING_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    consultantGoalPendingView({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_consultant_goal_pendings.id': id,
        },
        array: false,
      })
      return axiosIns
        .post('/data/consultant_goal_pendings', data)
        .then(response => {
          commit('CONSULTANT_GOAL_PENDING', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    consultantGoalPendingSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      return axiosIns
        .post('/data/consultant_goal_pendings/saveData', data)
        .then(response => {
          commit('CONSULTANT_GOAL_PENDING_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}

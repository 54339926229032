import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCount: 0,
    dataItem: {
      id: null,
      title: null,
      content: null,
      lesson_date: null,
      file: null,
      upload_doc: null,
      submitStatus: false,
    },
    dataSaveStatus: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCount(state) {
      return state.dataCount
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSaveStatus
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNT(state, data) {
      state.dataCount = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSaveStatus = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        title: null,
        content: null,
        lesson_date: null,
        file: null,
        upload_doc: null,
        submitStatus: false,
      }
    },
  },
  actions: {
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/portal/lessons_learned', data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getData({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_portal_lessons_learned.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/portal/lessons_learned', data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const formData = new FormData()
      formData.append('id', postData.id)
      formData.append('title', postData.title)
      formData.append('content', postData.content)
      formData.append('lesson_date', postData.lesson_date)
      formData.append('old_file', postData.file)
      formData.append('doc', postData.upload_doc)
      return axiosIns
        .post('/portal/lessons_learned/saveData', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    deleteData({ commit }, id) {
      const data = JSON.stringify({
        id,
      })
      axiosIns
        .post('/portal/lessons_learned/deleteData', data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}

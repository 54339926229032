import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    interviews: [],
    customersList: [],
    interviewCounters: [],
    interviewsCount: null,
    interview: {
      id: null,
      interview_number: null,
      initial: null,
      content: null,
      idate: null,
      itime: null,
      status: null,
      testdrive: null,
      chasis: null,
      inallar2_advert: null,
      license_plate_swap: null,
      pollcode: null,
      poll: null,
      file: null,
      file_number: null,
      invoice_no: null,
      expertise_type: null,
      ins_extras: null,
      id_com_adverts: null,
      id_com_advert: null,
      id_com_interview_insurance_company: null,
      id_com_meet: null,
      id_com_interview_subject: null,
      id_com_customer: null,
      id_com_user: null,
      id_com_user1: null,
      id_com_status: null,
      id_com_interview_type: null,
      id_com_brand: null,
      id_com_model: null,
      id_com_swap_brand: null,
      id_com_swap_model: null,
      id_com_cars: null,
      id_com_swapcar: null,
      id_com_interview_status: null,
      id_com_activity: null,
      id_com_realty_project: null,
      id_com_realty_type: null,
      id_com_realty_number: null,
      id_com_insurance_company: null,
      id_com_service_tracing: null,
      id_com_realty_sale_status: null,
      interview_type_component: '',
      id_com_testdrive_form_sms: null,
      orderSpareParts: {
        id_com_brand: null,
        id_com_sparepart_order_type: null,
        parts: [],
      },
      testDriveForm: {
        id_com_testdrive_form_sms: null,
        id_com_model: null,
        currentVehicle: null,
        vehicle: null,
        sms: null,
      },
      interview_poll: {},
      detailForm: false,
      submitStatus: false,
    },
    viewInterview: {
      interview_type: null,
      interview_number: null,
      idate: null,
      itime: null,
      status: null,
      statusValue: null,
      customer: null,
      company: null,
      phone: null,
      brand: null,
      model: null,
      meet: null,
      testdrive: null,
      carInfo: null,
      i2CarInfo: null,
      status_code: null,
      interview_status: null,
      interview_subject: null,
      chasis: null,
      username: null,
      username1: null,
      pollcode: null,
      poll: null,
      realty_project: null,
      realty_type: null,
      id_com_user: null,
      id_com_customer: null,
      id_com_status: null,
      interview_type_component: null,
      lines: [],
    },
    editInterviewLine: {
      id: null,
      idate: null,
      itime: null,
      id_com_interview_status: null,
      id_com_meet: null,
      id_com_realty_project: null,
      id_com_realty_type: null,
      id_com_realty_number: null,
      id_com_status: null,
      content: null,
      submitStatus: false,
    },
    quickSearch: {
      keyword: '',
    },
    filterSearch: {
      alertDate: '0',
      id_com_brand: null,
      id_com_model: null,
      id_com_status: null,
      id_com_meet: null,
      id_com_user: null,
      id_com_interview_subject: null,
      id_com_realty_project: null,
      testdrive: null,
      status: null,
      sdate: null,
      edate: null,
    },
    testFormSms: {
      code: null,
      result: null,
      id: null,
      sms_status: null,
      error: null,
      countDown: 180,
    },
    interviewSave: {
      message: null,
      status: null,
      id: null,
      orderNumber: null,
    },
    testFormSave: {
      message: null,
      status: null,
      id: null,
      orderNumber: null,
    },
    dataLoading: true,
    redirectModal: {
      status: false,
      id_com_interview_type: null,
      id_com_user: null,
      content: null,
    },
  },
  getters: {
    getInterviews(state) {
      return state.interviews
    },
    getEditInterviewLine(state) {
      return state.editInterviewLine
    },
    getCustomersList(state) {
      return state.customersList
    },
    getInterviewsCount(state) {
      return state.interviewsCount
    },
    getInterviewCounters(state) {
      return state.interviewCounters
    },
    getQuickSearch(state) {
      return state.quickSearch
    },
    getInterview(state) {
      return state.interview
    },
    getTestFormSms(state) {
      return state.testFormSms
    },

    getViewInterview(state) {
      return state.viewInterview
    },
    getInterviewSaveStatus(state) {
      return state.interviewSave
    },
    getTestFormSaveStatus(state) {
      return state.testFormSave
    },
    getInterviewLoading(state) {
      return state.dataLoading
    },
    getInterviewFilters(state) {
      return state.filterSearch
    },
    redirectModal(state) {
      return state.redirectModal
    },
  },
  mutations: {
    INTERVIEWS_LIST(state, data) {
      state.interviews = data
    },
    CUSTOMERS_LIST(state, data) {
      state.customersList = data
    },
    INTERVIEW_VIEW(state, data) {
      state.viewInterview = data
    },
    SET_INTERVIEW(state, data) {
      state.interview.id_com_interview_type = data.id_com_interview_type
      state.interview.id_com_interview_status = data.id_com_interview_status
    },
    INTERVIEWS_COUNT(state, data) {
      state.interviewsCount = data
    },
    INTERVIEW_COUNTERS(state, data) {
      state.interviewCounters = data
    },
    INTERVIEW_SAVE(state, data) {
      state.interviewSave = data
    },
    TESTFORM_SAVE(state, data) {
      state.testFormSave = data
    },
    TESTFORM_SMS_RESULT(state, data) {
      state.testFormSms = data
    },
    TESTFORM_SMS_CONFIRM(state, data) {
      state.testFormSms.result = data.result
      state.testFormSms.error = data.error
    },
    SET_INTERVIEW_NUMBER(state, data) {
      state.interview.interview_number = data
    },
    SET_EDIT_LINE(state, data) {
      state.editInterviewLine = {
        id: data.id,
        idate: data.idate,
        itime: data.itime,
        id_com_interview_status: data.id_com_interview_status,
        id_com_meet: data.id_com_meet,
        id_com_realty_project: data.id_com_realty_project,
        id_com_realty_type: data.id_com_realty_type,
        id_com_status: data.id_com_status,
        content: data.content,
      }
    },
    INTERVIEW_RESET(state, data) {
      if (data.status) {
        state.interview = {
          id: null,
          interview_number: null,
          initial: null,
          content: null,
          idate: null,
          itime: null,
          status: null,
          testdrive: null,
          chasis: null,
          inallar2_advert: null,
          license_plate_swap: null,
          pollcode: null,
          poll: null,
          realty_project: null,
          realty_type: null,
          file: null,
          file_number: null,
          invoice_no: null,
          expertise_type: null,
          ins_extras: null,
          id_com_adverts: null,
          id_com_advert: null,
          id_com_interview_insurance_company: null,
          id_com_meet: null,
          id_com_interview_subject: null,
          id_com_customer: null,
          id_com_user: null,
          id_com_user1: null,
          id_com_status: null,
          id_com_interview_type: null,
          id_com_brand: null,
          id_com_model: null,
          id_com_swap_brand: null,
          id_com_swap_model: null,
          id_com_cars: null,
          id_com_swapcar: null,
          id_com_interview_status: null,
          id_com_activity: null,
          id_com_realty_project: null,
          id_com_realty_type: null,
          id_com_realty_number: null,
          id_com_insurance_company: null,
          id_com_service_tracing: null,
          interview_type_component: '',
          id_com_testdrive_form_sms: null,
          orderSpareParts: {
            id_com_brand: null,
            id_com_sparepart_order_type: null,
            parts: [],
          },
          testDriveForm: {
            id_com_testdrive_form_sms: null,
            current_vehicle: null,
            id_com_model: null,
            vehicle: null,
            sms: null,
          },
          interview_poll: {},
          detailForm: false,
          submitStatus: false,
        }
        state.redirectModal = {
          status: false,
          id_com_interview_type: null,
          id_com_user: null,
          content: null,
        }
      }
    },
    RESET_TESTFORM_SMS_RESULT(state) {
      state.testFormSms = {
        code: null,
        result: null,
        sms_status: null,
        error: null,
        id: null,
        countDown: 180,
      }
    },
    SET_DATA_LOADING(state, data) {
      state.dataLoading = data
    },
    FILTER_RESET(state, data) {
      if (data.status) {
        state.filterSearch = {
          alertDate: '0',
          id_com_brand: null,
          id_com_model: null,
          id_com_status: null,
          id_com_meet: null,
          id_com_user: null,
          id_com_interview_subject: null,
          id_com_realty_project: null,
          testdrive: null,
          status: null,
          sdate: null,
          edate: null,
        }
      }
    },
    RESET_TEST_SMS_FORM(state) {
      state.interview.testDriveForm = {
        id_com_testdrive_form_sms: null,
        current_vehicle: null,
        id_com_model: null,
        vehicle: null,
        sms: null,
      }
    },
  },
  actions: {
    interviewsList({ commit }, params) {
      commit('SET_DATA_LOADING', true)
      const data = JSON.stringify(params)
      return axiosIns
        .post('/data/interviews', data)
        .then(response => {
          commit('INTERVIEWS_LIST', response.data.data)
          commit('INTERVIEWS_COUNT', response.data.count)
          commit('INTERVIEW_COUNTERS', response.data.counters)
          commit('SET_DATA_LOADING', false)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    customersSearch({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/interviews/getCustomers', data)
        .then(response => {
          commit('CUSTOMERS_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
    interviewView({ commit }, interviewNumber) {
      const data = JSON.stringify({
        interview_number: interviewNumber,
      })
      return axiosIns
        .post('/data/interviews/viewInterview', data)
        .then(response => {
          commit('INTERVIEW_VIEW', response.data.data)
          commit('SET_INTERVIEW', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    interviewSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      return axiosIns
        .post('/data/interviews/saveData', data)
        .then(response => {
          commit('INTERVIEW_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    testFormSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      return axiosIns
        .post('/data/interviews/saveTestForm', data)
        .then(response => {
          commit('TESTFORM_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    interviewReset({ commit }) {
      commit('INTERVIEW_RESET', { status: true })
    },
    testFormSmsSend({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/interviews/testFormSms', data)
        .then(response => {
          commit('TESTFORM_SMS_RESULT', response.data)
        })
        .catch(error => console.error(error))
    },
    testFormSmsConfirm({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      return axiosIns
        .post('/data/interviews/testFormConfirm', data)
        .then(response => {
          commit('TESTFORM_SMS_CONFIRM', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    getInterviewNumber({ commit }) {
      axiosIns
        .post('/data/interviews/getInterviewNumber')
        .then(response => {
          commit('SET_INTERVIEW_NUMBER', response.data.data)
        })
        .catch(error => console.error(error))
    },
    setEditLine({ commit }, data) {
      commit('SET_EDIT_LINE', data)
    },
    interviewRedirect({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      return axiosIns
        .post('/data/interviews/sendInterview', data)
        .then(response => {
          commit('INTERVIEW_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    interviewFilterReset({ commit }) {
      commit('FILTER_RESET', { status: true })
    },
    removeData({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post('/data/interviews/deleteData', data)
        .then(response => {
          commit('INTERVIEW_SAVE', response.data)
          return response.data.status
        })
        .catch(error => console.error(error))
    },
  },
}

import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    stocks: [],
    stocksCount: 0,
    allocateStocks: [],
    allocateStocksCount: 0,
    stock: {
      id: null,
      chasis: null,
      engine: null,
      myear: null,
      invoice_date: null,
      invoice_no: null,
      hardware: null,
      cc: null,
      purchase_price: null,
      sale_price: null,
      discount: null,
      poll: null,
      special_stock: null,
      id_com_brand: null,
      id_com_model: null,
      id_com_fuel: null,
      id_com_gear: null,
      id_com_color: null,
      id_com_stock_statuses: null,
      id_com_location: null,
      state: null,
    },
    stockSave: {
      message: null,
      status: null,
      id: null,
    },
    logoStock: [],
    addLogoStock: {
      id: null,
      chasis: null,
      engine: null,
      myear: null,
      invoice_date: null,
      invoice_no: null,
      hardware: null,
      cc: null,
      purchase_price: null,
      sale_price: null,
      discount: null,
      poll: null,
      special_stock: null,
      id_com_brand: null,
      id_com_model: null,
      id_com_fuel: null,
      id_com_gear: null,
      id_com_color: null,
      id_com_stock_statuses: null,
      id_com_location: null,
      state: null,
    },
    quickSearch: {
      keyword: '',
    },
    stockSync: {
      data: null,
      status: null,
      error: null,
    },
    batchStock: {
      id_com_brand: null,
      id_com_model: null,
      hardware: null,
      myear: null,
      cc: null,
      id_com_fuel: null,
      id_com_gear: null,
      id_com_color: null,
      quantity: 1,
    },
    allocateStockModal: {
      modalStatus: false,
      stock: {
        brand: null,
        model: null,
        arac_cinsi: null,
        sase: null,
        motor_no: null,
        model_yili: null,
        fatura_tarihi: null,
        fatura_no: null,
        alim_tutari: null,
        id_com_brand: null,
        allocateStocks: null,
      },
      dataList: [],
    },
  },
  getters: {
    getStocks(state) {
      return state.stocks
    },
    getStocksCount(state) {
      return state.stocksCount
    },
    getStock(state) {
      return state.stock
    },
    getStockSaveStatus(state) {
      return state.stockSave
    },
    getQuickSearch(state) {
      return state.quickSearch
    },
    getLogoStock(state) {
      return state.logoStock
    },
    addLogoStock(state) {
      return state.addLogoStock
    },
    stockSync(state) {
      return state.stockSync
    },
    batchStock(state) {
      return state.batchStock
    },
    allocateStocks(state) {
      return state.allocateStocks
    },
    allocateStocksCount(state) {
      return state.allocateStocksCount
    },
    allocateStockModal(state) {
      return state.allocateStockModal
    },
  },
  mutations: {
    SET_STOCKS(state, data) {
      state.stocks = data
    },
    SET_STOCK(state, data) {
      state.stock = data
    },
    SET_STOCK_COUNT(state, data) {
      state.stocksCount = data
    },
    SET_STOCK_SAVE(state, data) {
      state.stockSave = data
    },
    LOGO_STOCK(state, data) {
      state.logoStock = data
    },
    ADD_LOGO_STOCK(state, data) {
      state.addLogoStock = data
    },
    SET_STOCK_SYNC(state, data) {
      state.stockSync = data
    },
    RESET_BATCH_STOCK(state) {
      state.batchStock = {
        id_com_brand: null,
        id_com_model: null,
        hardware: null,
        myear: null,
        cc: null,
        id_com_fuel: null,
        id_com_gear: null,
        id_com_color: null,
        quantity: 1,
      }
    },
    SET_ALLOCATE_STOCKS(state, data) {
      state.allocateStocks = data.data
      state.allocateStocksCount = data.count
    },
    SET_ALLOCATE_STOCK_MODAL(state, data) {
      state.allocateStockModal.dataList = data.data
    },
  },
  actions: {
    stocks({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/stock', data)
        .then(response => {
          commit('SET_STOCKS', response.data.data)
          commit('SET_STOCK_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getAllocateStocks({ commit }, params) {
      return axiosIns
        .post('/data/stock/allocateStocks', params)
        .then(response => {
          commit('SET_ALLOCATE_STOCKS', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    getAllocateStockList({ commit }, params) {
      return axiosIns
        .post('/data/stock/allocateStocks', params)
        .then(response => {
          commit('SET_ALLOCATE_STOCK_MODAL', response.data)
          return true
        })
        .catch(error => console.error(error))
    },
    stock({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_stock.id': id,
        },
        array: false,
      })
      return axiosIns
        .post('/data/stock', data)
        .then(response => {
          commit('SET_STOCK', response.data.data)
          commit('ADD_LOGO_STOCK', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    addStock({ commit }, chasis) {
      const data = JSON.stringify({
        select: [
          '_mssql_aracstok.SASE as chasis',
          '_mssql_aracstok.MOTOR_NO as engine',
          '_mssql_aracstok.MODEL_YILI as myear',
          '_mssql_aracstok.FATURA_TARIHI as invoice_date',
          '_mssql_aracstok.FATURA_NO as invoice_no',
          '_mssql_aracstok.ARAC_CINSI as hardware',
          '_mssql_aracstok.MOTOR_GUCU as cc',
          '_mssql_aracstok.ALIM_TUTARI as purchase_price',
          '"" as sale_price',
          '"" as discount',
          '"" as accessories',
          '"" as sale_status',
          '"" as poll',
          '"" as special_stock',
          '_mssql_aracstok.ID_COM_BRAND as id_com_brand',
          '"" as id_com_model',
          '"" as id_com_fuel',
          '"" as id_com_gear',
          '"" as id_com_color',
          '"" as id_com_location',
          '"" as state',
        ],
        where: {
          '_mssql_aracstok.SASE': chasis,
        },
        array: false,
      })
      return axiosIns
        .post('/data/stock/logoStock', data)
        .then(response => {
          commit('ADD_LOGO_STOCK', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    addStockBatch({ commit }) {
      const data = JSON.stringify({
        select: [
          '_mssql_aracstok.SASE as chasis',
          '_mssql_aracstok.MOTOR_NO as engine',
          '_mssql_aracstok.MODEL_YILI as myear',
          '_mssql_aracstok.FATURA_TARIHI as invoice_date',
          '_mssql_aracstok.FATURA_NO as invoice_no',
          '_mssql_aracstok.ARAC_CINSI as hardware',
          '_mssql_aracstok.MOTOR_GUCU as cc',
          '_mssql_aracstok.ALIM_TUTARI as purchase_price',
          '"" as sale_price',
          '"" as discount',
          '"" as accessories',
          '"" as sale_status',
          '"" as poll',
          '"" as special_stock',
          '_mssql_aracstok.ID_COM_BRAND as id_com_brand',
          '"" as id_com_model',
          '"" as id_com_fuel',
          '"" as id_com_gear',
          '"" as id_com_color',
          '"" as id_com_location',
          '"" as state',
        ],
        where: {
          'com_stock.id': null,
        },
      })
      return axiosIns
        .post('/data/stock/logoStock', data)
        .then(response => {
          commit('LOGO_STOCK', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    logoStock({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post('/data/stock/logoStock', data)
        .then(response => {
          commit('LOGO_STOCK', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    stockSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/stock/saveData', data)
        .then(response => {
          commit('SET_STOCK_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    stockDelete({ commit }, id) {
      return axiosIns
        .post('/data/stock/deleteData', { id })
        .then(response => {
          commit('SET_STOCK_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    // stockBatchSave({ commit }, postData) {
    //   const data = JSON.stringify({ formData: postData })
    //   axiosIns
    //     .post('/data/stock/saveBatchData', data)
    //     .then(response => {
    //       commit('SET_STOCK_SAVE', response.data)
    //     })
    //     .catch(error => console.error(error))
    // },
    stockSearch({ commit }, chasis) {
      return axiosIns
        .post('/data/stock/stockSearch', { chasis })
        .then(response => {
          commit('SET_STOCKS', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    allocateStocksInsert({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/data/stock/allocateStockInsert', data)
        .then(response => {
          commit('SET_STOCK_SAVE', response.data)
          return true
        })
        .catch(error => console.error(error))
    },
    allocateStockBatch({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post('/data/stock/allocateStockBatch', data)
        .then(response => {
          commit('SET_STOCK_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    stockSync({ commit }, params) {
      return axiosIns
        .post('/data/stock/getStockSync', params)
        .then(response => {
          commit('SET_STOCK_SYNC', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    matchStock({ commit }, params) {
      return axiosIns
        .post('/data/stock/matchStock', params)
        .then(response => {
          commit('SET_STOCK_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}

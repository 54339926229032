import axiosIns from '@/libs/axios'

const ROOT_URL = '/data/crc'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    dataItem: {
      id: null,
      close_code: null,
      close_date: null,
      id_com_brand: null,
      id_com_location: null,
      id_com_crc_status: null,
      id_com_cars: null,
      id_com_customer: null,
      id_com_customer_related_person: null,
      id_com_interview: null,
      id_com_crc_type: null,
      id_com_user: null,
      id_com_user_1: null,
    },
    filterData: {
      keywords: null,
      id_com_brand: [],
      id_com_location: [],
      id_com_crc_status: ['1', '2', '5'],
      id_com_user: null,
      id_com_crc_type: null,
      detailSearch: false,
    },
    crcTypes: [
      {
        id: 1,
        title: 'Servis Bakım Hatırlatma Araması',
      },
      {
        id: 4,
        title: 'Servis Memnuniyet Araması',
      },
      {
        id: 2,
        title: 'Sıfır Araç Satışı Memnuniyet Araması',
      },
      {
        id: 3,
        title: 'Cevapsız Anket Memnuniyet Araması',
      },

    ],
    dataLoading: false,
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    dataLoading(state) {
      return state.dataLoading
    },
    filterData(state) {
      return state.filterData
    },
    crcTypes(state) {
      return state.crcTypes
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        close_code: null,
        close_date: null,
        id_com_brand: null,
        id_com_location: null,
        id_com_crc_status: null,
        id_com_cars: null,
        id_com_customer: null,
        id_com_customer_related_person: null,
        id_com_interview: null,
        id_com_crc_type: null,
        id_com_user: null,
        id_com_user_1: null,
      }
    },
    SET_DATA_LOADING(state, status) {
      state.dataLoading = status
    },
  },
  actions: {
    getDataList({ commit }, params) {
      commit('SET_DATA_LOADING', true)
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LOADING', false)
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, id) {
      commit('SET_DATA_LOADING', true)
      const data = JSON.stringify({
        where: {
          'com_crc.id': id,
        },
        array: false,
      })
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LOADING', false)
          commit('SET_DATA_ITEM', response.data.data)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    batchInsert({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post(`${ROOT_URL}/batchSaveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}

import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    reportData: {
      counters: {
        sent: 0,
        pending: 0,
      },
      dataList: [],
      dataCount: 0,
    },
    filterParams: {
      sdate: null,
      edate: null,
      id_com_brand: null,
    },
    loadingReport: null,
  },
  getters: {
    reportData(state) {
      return state.reportData
    },
    filterParams(state) {
      return state.filterParams
    },
    loadingReport(state) {
      return state.loadingReport
    },
  },
  mutations: {
    SET_REPORT_DATA(state, data) {
      state.reportData = data
    },
    SET_REPORT_LOADING(state, data) {
      state.loadingReport = data
    },
    RESET_REPORT_DATA(state) {
      state.reportData = {
        counters: {
          sent: 0,
          pending: 0,
        },
        dataList: [],
        dataCount: 0,
      }
    },
    RESET_REPORT_FILTER_DATA(state) {
      state.filterParams = {
        sdate: null,
        edate: null,
        id_com_brand: null,
      }
    },
  },
  actions: {
    getReport({ commit }, params) {
      const data = JSON.stringify(params)
      commit('SET_REPORT_LOADING', true)
      axiosIns
        .post('/reports/service/service_work_order_sms', data)
        .then(response => {
          commit('SET_REPORT_DATA', response.data)
          commit('SET_REPORT_LOADING', false)
        })
        .catch(error => console.log(error))
    },
  },
}

export default [
  {
    path: '/portal/lessons-learned',
    name: 'LessonsLearned',
    component: () => import('@/views/Portal/LessonsLearned/Index.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Öğrenilmiş Dersler',
          active: true,
        },
      ],
      resource: 'PORTAL_LESSONS_LEARNED_LIST',
      action: 'read',
    },
  },
  {
    path: '/portal/lessons-learned/add',
    name: 'LessonsLearnedAdd',
    component: () => import('@/views/Portal/LessonsLearned/Add.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Öğrenilmiş Dersler',
          to: '/portal/lessons-learned',
          active: false,
        },
        {
          text: 'Ekle',
          active: true,
        },
      ],
      resource: 'PORTAL_LESSONS_LEARNED_ADD',
      action: 'read',
    },
  },
  {
    path: '/portal/lessons-learned/edit/:id',
    name: 'LessonsLearnedEdit',
    component: () => import('@/views/Portal/LessonsLearned/Edit.vue'),
    meta: {
      pageTitle: 'Portal',
      breadcrumb: [
        {
          text: 'Öğrenilmiş Dersler',
          to: '/portal/lessons-learned',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'PORTAL_LESSONS_LEARNED_EDIT',
      action: 'read',
    },
  },
]

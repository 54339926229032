export default [
  {
    path: '/administrative/request/add',
    name: 'AdministrativeRequestAdd',
    component: () => import('@/views/Administrative/Add.vue'),
    meta: {
      pageTitle: 'Talepler',
      breadcrumb: [
        {
          to: '/administrative/request',
          text: 'Taleplerim',
        },
        {
          text: 'Talep Et',
          active: true,
        },
      ],
      resource: 'ADMINISTRATIVE_REQUEST_ADD',
      action: 'read',
    },
  },
  {
    path: '/administrative/request',
    name: 'AdministrativeRequest',
    component: () => import('@/views/Administrative/Index.vue'),
    meta: {
      pageTitle: 'Talepler',
      breadcrumb: [
        {
          text: 'Taleplerim',
          active: true,
        },
      ],
      resource: 'ADMINISTRATIVE_REQUEST',
      action: 'read',
    },
  },
  {
    path: '/administrative/request/view/:id',
    name: 'AdministrativeRequestView',
    component: () => import('@/views/Administrative/View.vue'),
    meta: {
      pageTitle: 'Talepler',
      breadcrumb: [
        {
          to: '/administrative/request',
          text: 'Taleplerim',
        },
        {
          text: 'Görüntüle',
          active: true,
        },
      ],
      resource: 'ADMINISTRATIVE_REQUEST',
      action: 'read',
    },
  },
  {
    path: '/administrative/request/list',
    name: 'AdministrativeRequestList',
    component: () => import('@/views/Administrative/RequestList.vue'),
    meta: {
      pageTitle: 'Talepler',
      breadcrumb: [
        {
          text: 'Gelen Talepler',
          active: true,
        },
      ],
      resource: 'ADMINISTRATIVE_REQUEST_LIST',
      action: 'read',
    },
  },
]

import moment from 'moment/moment'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    dataList: [],
    callList: [],
    istatistics: {
      day_call_target: 0,
      day_calls: 0,
      month_call_target: 0,
      month_calls: 0,
    },
    periods: [],
    dataCount: 0,
    dataItem: {
      id: null,
      poli_id: null,
      police_no: null,
      call_date: null,
      id_com_user: null,
    },
    detailModal: {
      status: false,
      customer: null,
      hes_kod: null,
      poli_id: null,
      police_no: null,
    },
    interviewLine: {
      newCustomer: false,
      interview_number: null,
      company_name: null,
      tckn: null,
      name: null,
      phone: null,
      idate: moment().format('YYYY-MM-DD'),
      itime: moment().format('HH:MM'),
      content: null,
      customer_kvkk: '0',
      customer_bulletin: 1,
      customer_kvkkdate: moment().format('YYYY-MM-DD HH:mm:ss'),
      customer_bulletindate: moment().format('YYYY-MM-DD HH:mm:ss'),
      customer_id_com_kvkk_type: 3,
      customer_id_com_bulletin_type: 3,
      id_com_meet: null,
      id_com_interview_subject: null,
      id_com_interview_status: null,
      id_com_customer: null,
      id_com_insurance_renewal: null,
    },
    dataLoading: false,
    saveData: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    getDataList(state) {
      return state.dataList
    },
    getPeriods(state) {
      return state.periods
    },
    getDetailModal(state) {
      return state.detailModal
    },
    getCallList(state) {
      return state.callList
    },
    dataCount(state) {
      return state.dataCount
    },
    getData(state) {
      return state.dataItem
    },
    getIstatistics(state) {
      return state.istatistics
    },
    saveDataStatus(state) {
      return state.saveData
    },
    dataLoading(state) {
      return state.dataLoading
    },
    interviewLine(state) {
      return state.interviewLine
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_CALL_LIST(state, data) {
      state.callList = data
    },
    SET_PERIODS_LIST(state, data) {
      state.periods = data
    },
    SET_ITEM_DATA(state, data) {
      state.dataItem = data
    },
    SET_ISTATISTICS_DATA(state, data) {
      state.istatistics = data
    },
    SET_DATA_COUNT(state, data) {
      state.dataCount = data
    },
    SAVE_DATA_STATUS(state, data) {
      state.saveData = data
    },
    SET_DATA_RESET(state) {
      state.dataItem = {
        id: null,
        poli_id: null,
        police_no: null,
        call_date: null,
        id_com_user: null,
      }
    },
    RESET_INTERVIEW_LINE(state) {
      state.interviewLine = {
        newCustomer: false,
        company_name: null,
        tckn: null,
        name: null,
        phone: null,
        idate: moment().format('YYYY-MM-DD'),
        itime: moment().format('HH:MM'),
        content: null,
        customer_kvkk: '0',
        id_com_meet: null,
        id_com_interview_subject: null,
        id_com_interview_status: null,
        id_com_customer: null,
      }
    },
    SET_DATA_LOADING(state, data) {
      state.dataLoading = data
    },
  },
  actions: {
    dataList({ commit }, params) {
      commit('SET_DATA_LOADING', true)
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/insurance_reminder', data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          // commit('SET_DATA_COUNT', response.data.count)
          commit('SET_DATA_LOADING', false)
        })
        .catch(error => console.error(error))
    },
    callList({ commit }, params) {
      commit('SET_DATA_LOADING', true)
      const data = JSON.stringify(params)
      axiosIns
        .post('/data/insurance_reminder/getCallList', data)
        .then(response => {
          commit('SET_DATA_LOADING', false)
          commit('SET_CALL_LIST', response.data.data)
          commit('SET_ISTATISTICS_DATA', response.data.istatistics)
          commit('SET_PERIODS_LIST', response.data.periods)
          commit('SET_DATA_COUNT', response.data.count)
        })
        .catch(error => console.error(error))
    },
    dataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_insurance_reminder.id': id,
        },
        array: false,
      })
      axiosIns
        .post('/data/insurance_reminder', data)
        .then(response => {
          commit('SET_ITEM_DATA', response.data.data)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post('/data/insurance_reminder/saveData', data)
        .then(response => {
          commit('SAVE_DATA_STATUS', response.data)
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      return axiosIns
        .post('/data/insurance_reminder/deleteData', { id })
        .then(response => {
          commit('SAVE_DATA_STATUS', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    saveInterview({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post('/data/insurance_reminder/saveInterview', data)
        .then(response => {
          commit('SAVE_DATA_STATUS', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}

import axiosIns from '@/libs/axios'

const ROOT_URL = '/data/administrative_request'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    dataItem: {
      id: null,
      rcode: null,
      title: null,
      start_date: null,
      finish_date: null,
      content: null,
      id_com_administrative_request_status_code: null,
      id_com_administrative_request_status: null,
      id_com_administrative_request_subject: null,
      id_com_user: null,
      id_com_request_user: null,
      id_com_m_user: null,
      submitStatus: false,
    },
    filterData: {
      rcode: null,
      start_date: null,
      finish_date: null,
      id_com_administrative_request_status: null,
      id_com_administrative_request_subject: null,
      id_com_administrative_request_status_code: null,
      id_com_department: null,
      keywords: null,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    dataLoading: false,
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    filterData(state) {
      return state.filterData
    },
    dataItem(state) {
      return state.dataItem
    },
    dataLoading(state) {
      return state.dataLoading
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        rcode: null,
        title: null,
        start_date: null,
        finish_date: null,
        content: null,
        id_com_administrative_request_status_code: null,
        id_com_administrative_request_status: null,
        id_com_administrative_request_subject: null,
        id_com_user: null,
        id_com_request_user: null,
        id_com_m_user: null,
        submitStatus: false,
      }
    },
    RESET_FILTER_DATA(state) {
      state.filterData = {
        rcode: null,
        start_date: null,
        finish_date: null,
        id_com_administrative_request_status: null,
        id_com_administrative_request_subject: null,
        id_com_administrative_request_status_code: null,
        id_com_department: null,
        keywords: null,
      }
    },
    SET_DATA_LOADING(state, data) {
      state.dataLoading = data
    },
  },
  actions: {
    getDataList({ commit }, params) {
      commit('SET_DATA_LOADING', true)
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
          commit('SET_DATA_LOADING', false)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, id) {
      commit('SET_DATA_LOADING', true)
      const data = JSON.stringify({
        where: {
          'com_administrative_request.id': id,
        },
        array: false,
      })
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          commit('SET_DATA_LOADING', false)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    resetDataItem({ commit }) {
      commit('RESET_DATA_ITEM')
    },
    resetFilterData({ commit }) {
      commit('RESET_FILTER_DATA')
    },
  },
}

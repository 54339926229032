import axiosIns from '@/libs/axios'

const ROOT_URL = '/rentals/rental_stocks'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    dataCounters: [],
    waitDataList: [],
    waitDataCounts: 0,
    dataItem: {
      arackodu: null,
      title: null,
      myear: null,
      chasis: null,
      engine: null,
      licence_plate: null,
      license_number: null,
      arvento_no: null,
      utts_no: null,
      kabis: null,
      arvento: null,
      kredi: null,
      amortisman: null,
      tasitmatik: null,
      hgs: null,
      utts: null,
      id_com_swap_brand: null,
      id_com_swap_model: null,
      id_com_fuel: null,
      id_com_gear: null,
      id_com_color: null,
      id_com_rent_stock_status: null,
      registered: null,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    searchData: {
      keyword: null,
    },
    detailFilter: {
      id_com_rent_stock_status: null,
      id_com_swap_brand: null,
      id_com_swap_model: null,
      id_com_fuel: null,
      id_com_gear: null,
      sdate: null,
      edate: null,
      sale_sdate: null,
      sale_edate: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataCounters(state) {
      return state.dataCounters
    },
    waitDataList(state) {
      return state.waitDataList
    },
    waitDataCounts(state) {
      return state.waitDataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    searchData(state) {
      return state.searchData
    },
    detailFilter(state) {
      return state.detailFilter
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_COUNTERS(state, data) {
      state.dataCounters = data
    },
    SET_WAIT_DATA_LIST(state, data) {
      state.waitDataList = data
    },
    SET_WAIT_DATA_COUNTS(state, data) {
      state.waitDataCounts = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        arackodu: null,
        title: null,
        myear: null,
        chasis: null,
        engine: null,
        licence_plate: null,
        license_number: null,
        arvento_no: null,
        utts_no: null,
        kabis: null,
        arvento: null,
        kredi: null,
        amortisman: null,
        tasitmatik: null,
        hgs: null,
        utts: null,
        id_com_swap_brand: null,
        id_com_swap_model: null,
        id_com_fuel: null,
        id_com_gear: null,
        id_com_color: null,
        id_com_rent_stock_status: null,
        registered: null,
      }
    },
  },
  actions: {
    getWaitDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(`${ROOT_URL}/getWaitStocks`, data)
        .then(response => {
          commit('SET_WAIT_DATA_LIST', response.data.data)
          commit('SET_WAIT_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
          // commit('SET_DATA_COUNTERS', response.data.counters)
        })
        .catch(error => console.error(error))
    },

    getDataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_rental_stocks.id': id,
        },
        array: false,
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    getLogoStock({ commit }, aracKodu) {
      return axiosIns
        .post(`${ROOT_URL}/getLogoStock`, { arac_kodu: aracKodu })
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    hiddenLogoStock({ commit }, aracKodu) {
      return axiosIns
        .post(`${ROOT_URL}/hiddenLogoStock`, { arac_kodu: aracKodu })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    getDataCounters({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post(`${ROOT_URL}/counters`, data)
        .then(response => {
          commit('SET_DATA_COUNTERS', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
  },
}

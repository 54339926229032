import moment from 'moment/moment'
import axiosIns from '@/libs/axios'

const ROOT_URL = '/data/insurance_call_lines'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    dataItem: {
      id: null,
      content: null,
      calldate: null,
      id_com_user: null,
      id_com_insurance_call_status: null,
      id_com_insurance_call: null,
      idate: moment().format('YYYY-MM-DD'),
      itime: moment().format('HH:MM'),
      id_com_interview_type: '8',
      id_com_interview_subject: null,
      id_com_interview_status: null,
      id_com_brand: '10',
      id_com_meet: null,
    },
    modalData: {
      status: false,
      saving: false,
      loading: false,
    },
    dataLoading: false,
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    dataLoading(state) {
      return state.dataLoading
    },
    dataItemLoading(state) {
      return state.modalData.loading
    },
    modalData(state) {
      return state.modalData
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        content: null,
        calldate: null,
        id_com_user: null,
        id_com_insurance_call_status: null,
        id_com_insurance_call: null,
        idate: moment().format('YYYY-MM-DD'),
        itime: moment().format('HH:MM'),
        id_com_interview_type: '8',
        id_com_interview_subject: '50',
        id_com_interview_status: null,
        id_com_brand: '10',
        id_com_meet: null,
      }
    },
    SET_DATA_LOADING(state, status) {
      state.dataLoading = status
    },
    SET_DATA_ITEM_LOADING(state, status) {
      state.modalData.loading = status
    },
  },
  actions: {
    getDataList({ commit }, params) {
      commit('SET_DATA_LOADING', true)
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LOADING', false)
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, id) {
      commit('SET_DATA_ITEM_LOADING', true)
      const data = JSON.stringify({
        where: {
          'com_insurance_call_lines.id': id,
        },
        array: false,
      })
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM_LOADING', false)
          commit('SET_DATA_ITEM', response.data.data)
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
